var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "dropdown-body d-flex"
  }, [_c('div', {
    staticClass: "companies-block"
  }, [_vm._l(_setup.mainCompanies, function (company) {
    return _c('SidedarCompanyItem', {
      key: company.id,
      attrs: {
        "companyId": company.id,
        "name": company.name,
        "photoUrl": company.photoUrl,
        "isCurrent": _setup.checkIsCurrentCompany(company.id)
      },
      on: {
        "update-current": _setup.updateCurrentCompany
      }
    });
  }), _c('div', {}, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center py-2"
  }, [_c('div', {
    staticClass: "add-company-circle rounded-circle cursor-pointer d-flex align-items-center justify-content-center display-2",
    on: {
      "click": _setup.openNewCompanyModal
    }
  }, [_vm._v(" + ")])])]), _setup.defaultCompany ? _c('SidedarCompanyItem', {
    staticClass: "pt-3",
    attrs: {
      "name": _setup.defaultCompany.name,
      "companyId": _setup.defaultCompany.id,
      "isCurrent": _setup.checkIsCurrentCompany(_setup.defaultCompany.id)
    },
    on: {
      "update-current": _setup.updateCurrentCompany
    }
  }) : _vm._e()], 2), _c('div', {
    staticClass: "content-container w-100"
  }, [_c('b-row', {
    staticClass: "m-0 h-100"
  }, [_c('b-col', {
    staticClass: "col-6 px-0 pt-2 company-block",
    attrs: {
      "align-self": "stretch"
    }
  }, [_setup.currentCompany ? _c('div', {
    staticClass: "dropdown-company-container d-flex align-items-center"
  }, [_setup.currentCompany.photoUrl ? _c('div', [_c('img', {
    staticClass: "rounded-circle d-block",
    attrs: {
      "width": "28px",
      "height": "28px",
      "src": _setup.currentCompany.photoUrl,
      "alt": "company-photo"
    }
  })]) : _c('div', {
    staticClass: "company-circle rounded-circle d-flex align-items-center justify-content-center display-2"
  }, [_vm._v(" " + _vm._s(_setup.initials(_setup.currentCompany.name)) + " ")]), _c('div', {
    staticClass: "display-2"
  }, [_vm._v(_vm._s(_setup.currentCompany.name))])]) : _vm._e(), _vm._l(_setup.pagesItems, function (item) {
    return _c('router-link', {
      key: item.routeName,
      staticClass: "sidebar-dropdown-item",
      class: item.class,
      attrs: {
        "to": {
          name: item.routeName
        }
      },
      on: {
        "click": function ($event) {
          return _setup.chooseCompanyItem();
        }
      }
    }, [_c('div', {
      staticClass: "text-muted display-2"
    }, [_vm._v(_vm._s(item.title))])]);
  })], 2), _c('b-col', {
    staticClass: "col-6 px-0 pt-2 profile-block d-flex flex-column justify-content-between",
    attrs: {
      "align-self": "stretch"
    }
  }, [_c('div', [_c('div', {
    staticClass: "dropdown-user-container d-flex align-items-center"
  }, [_c('div', [_vm.userPhoto ? _c('div', [_c('img', {
    staticClass: "rounded-circle d-block",
    attrs: {
      "width": "28px",
      "height": "28px",
      "src": _vm.userPhoto,
      "alt": "user-photo"
    }
  })]) : _c('div', [_c(_setup.DefaultPhotoSmall, {
    staticClass: "d-block",
    attrs: {
      "width": "28px",
      "height": "28px"
    }
  })], 1)]), _c('div', {
    staticClass: "display-2 user-name"
  }, [_vm._v(" " + _vm._s(_vm.userName) + " ")])]), _vm._l(_setup.profilePagesItems, function (item) {
    return _c('router-link', {
      key: item.routeName,
      staticClass: "sidebar-dropdown-item",
      attrs: {
        "to": {
          name: item.routeName
        }
      },
      on: {
        "click": function ($event) {
          return _setup.chooseProfileItem();
        }
      }
    }, [_c('div', {
      staticClass: "text-muted display-2"
    }, [_vm._v(_vm._s(item.title))])]);
  }), _c('div', {
    staticClass: "sidebar-dropdown-item",
    on: {
      "click": _setup.logout
    }
  }, [_c('div', {
    staticClass: "text-muted display-2"
  }, [_vm._v(_vm._s(_vm.$t('header.titles.logout')))])])], 2), _vm._t("locale-dropdown")], 2)], 1)], 1), _c('NewCompanyModal', {
    attrs: {
      "modal-id": "newCompanyModal"
    },
    on: {
      "saved": _setup.companyCreated
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
/* eslint-disable no-debugger, no-console */

// пакеты
// import '@/plugins/auth';

import auth from '@/plugins/auth-service';

auth.init();

import './registerServiceWorker.js';

// vue пакеты
import Vue from 'vue';
import { i18n } from './plugins/i18n';
import store from './store';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
//import VueApexCharts from 'vue-apexcharts'
//import VueTrix from 'vue-trix'
import router from './router';

import { apolloProvider } from '@/plugins/apollo';
import '@/plugins/ag-grid';
import layout from '@/layouts';
import BeeBasePage from '@/components/bee-base/layout/bee-base-page';
import settings from '@/views/Settings.vue';
import market from '@/views/Market.vue';
//import VueQrcodeReader from 'vue-qrcode-reader'
import { ModelSelect } from 'vue-search-select';
import VueUploadComponent from 'vue-upload-component';
import { Multiselect } from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
//import VueColor from 'vue-color'
import vSelect from 'vue-select';
import SvgIcon from '@/components/bee-base/SvgIcon';
import Loader from '@/components/bee-base/loaders/Loader';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueClipboard from 'vue-clipboard2';
import VueFormWizard from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import useLocale from '@/composables/useLocale';
const { restoreLocale } = useLocale();

import { registerModules } from './register-modules';

// import overviewModule from './modules/overview';
import employeesModule from './modules/employees';
import eventsModule from './modules/events';
import boxSettingsModule from './modules/boxSettings';
import profileModule from './modules/profile';
import analyticsModule from './modules/analytics';
import jobOrdersModule from './modules/jobOrders';
import outputModule from './modules/output';
import shiftsModule from './modules/shifts';
import companyModule from './modules/company';
import apiModule from './modules/api';
import lossReasonsModule from './modules/lossReasons';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueFormWizard);

Vue.component('Layout', layout);
Vue.component('BeeBasePage', BeeBasePage);
Vue.component('Settings', settings);
Vue.component('Market', market);

Vue.component('SvgIcon', SvgIcon);
Vue.component('Loader', Loader);
//Vue.component('apexchart', VueApexCharts)
//Vue.component('vue-trix', VueTrix)
Vue.component('ModelSelect', ModelSelect);
Vue.component('Multiselect', Multiselect);
//Vue.component('vue-color', VueColor)
Vue.component('FileUpload', VueUploadComponent);
Vue.component('VueSlider', VueSlider);
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('v-select', vSelect);

Vue.config.ignoredElements = [
  'a-scene',
  'a-camera',
  'a-assets',
  'a-image',
  'a-asset-item',
  'a-entity',
  'a-plane',
  'a-gltf-model',
  'a-sky',
  'a-text',
  'a-box',
];

// Плагины стандартные
Vue.use(BootstrapVue);
//Vue.use(VueQrcodeReader)

restoreLocale();

registerModules({
  // overview: overviewModule,
  employees: employeesModule,
  events: eventsModule,
  boxSettings: boxSettingsModule,
  profile: profileModule,
  analytics: analyticsModule,
  jobOrders: jobOrdersModule,
  output: outputModule,
  shifts: shiftsModule,
  company: companyModule,
  api: apiModule,
  lossReaons: lossReasonsModule,
});

new Vue({
  i18n,
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');

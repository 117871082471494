/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { openPage } from '@/plugins/analytics';
import auth from '@/plugins/auth-service';
import useUserStore from '@/composables/useUserStore';
import { ROUTE_NAMES, USER_ACTIONS, USER_SUBJECTS } from '@/utils/const.js';
import useAbility from '@/plugins/useAbility';
import getUserAbilities from '@/services/userAbilitiesQuery';

Vue.use(VueRouter);

const routes = [
  {
    path: '/register',
    name: 'SmartOeeRegister',
    component: () => import('@/views/registration/Register.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/activation',
    name: 'VerifyEmail',
    component: () => import('@/views/registration/VerifyEmail.vue'),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: '/password/reset',
    name: 'ResetPassword',
    component: () => import('@/views/ForgotPassword/ResetPassword.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/password/activate',
    name: 'ActivationPassword',
    component: () => import('@/views/ForgotPassword/ActivationPassword.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/password/restore&hash=:hash&lang=:lang',
    name: 'ConfirmPassword',
    component: () => import('@/views/ForgotPassword/ConfirmPassword.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/',
    name: ROUTE_NAMES.BASE_LAYOUT,
    component: () => import('@/layouts'),
    redirect: 'boxes',
    meta: {},
    children: [
      {
        path: 'boxes',
        name: 'Boxes',
        component: () => import('@/views/Boxes.vue'),
        meta: {
          allowAnonymous: false,
        },
      },
      {
        path: 'boxes/:deviceId',
        name: 'Boxinfo',
        component: () => import('@/views/BoxInfo.vue'),
        meta: {
          allowAnonymous: false,
        },
      },
      // {
      //   path: 'bot',
      //   name: 'BotLinks',
      //   component: () => import('@/views/support/BotLinks.vue'),
      //   meta: {
      //   },
      // },
      // {
      //   path: 'faq',
      //   name: 'Faq',
      //   component: () => import('@/views/settings/Faq.vue'),
      //   meta: {
      //   },
      // },
      // {
      //   path: 'api',
      //   name: 'ApiSettings',
      //   component: () => import('@/views/settings/ApiSettings.vue'),
      //   meta: {
      //   },
      // },
      {
        path: 'addbox',
        name: 'SettingsAddBox',
        component: () => import('@/views/settings/AddBox.vue'),
        meta: {
          allowAnonymous: false,
          ability: {
            subject: USER_SUBJECTS.NEW_WORK_CENTER,
            action: USER_ACTIONS.READ,
          },
        },
      },
      // {
      //   path: 'sensors/ar/:sensorGuid',
      //   name: 'SensorAr',
      //   component: () => import('@/views/SensorsAR.vue'),
      //   meta: {
      //   },
      // },
    ],
  },
  {
    path: '/signin-oidc',
    name: 'signin-oidc',
    component: () => import('@/views/oidc/Signin.vue'),
    meta: {
      allowAnonymous: true,
      onlyAnonymous: true,
    },
  },
  {
    path: '/signout-oidc',
    name: 'signout-oidc',
    component: () => import('@/views/oidc/Signout.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
];

const router = new VueRouter({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_PATH,
});

router.beforeEach(async (to, from, next) => {
  const { init, can, isAbility } = useAbility();
  const basePath = process.env.VUE_APP_BASE_PATH.replaceAll('/', '');
  const fromPath = window.location.pathname.replace(basePath, '').replaceAll('//', '/');
  const isMatchedPath = to.matched.length > 0;

  const user = await auth.getUser();

  const allowAnonymous = to.matched.some((record) => record.meta.allowAnonymous);

  if (!allowAnonymous && !user) {
    await auth.signInRedirect({ state: { target_url: fromPath } });
    return;
  }

  if (!isAbility && user) {
    const userAbilities = await getUserAbilities();
    init(userAbilities);
  }

  const { init: initUserStore, userLogin } = useUserStore();

  if (user) {
    initUserStore(user);
  }

  const onlyAnonymousUser = to.matched.some((record) => record.meta.onlyAnonymous);

  if (user && (onlyAnonymousUser || !isMatchedPath)) {
    next({ name: 'Boxes' });
    return;
  }

  const canRoute =
    !to.meta?.ability?.subject || can(to.meta?.ability?.action, to.meta?.ability?.subject);

  if (allowAnonymous || canRoute) {
    next();
  } else {
    const query = canRoute ? { from: fromPath } : {};
    next({ name: 'Boxes', query });
  }
});

router.afterEach((to, from) => {
  openPage(to);
});

// регистронезависимые роуты
router.history.getCurrentLocation = () => {
  let path = window.location.pathname;
  let base = router.history.base;

  // Removes base from path (case-insensitive)
  if (base && path.toLowerCase().indexOf(base.toLowerCase()) === 0) {
    path = path.slice(base.length);
  }
  return (path || '/') + window.location.search + window.location.hash;
};
export default router;

import { ROUTE_NAMES } from '@/utils/const.js';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
const Output = () => import('./pages');

const moduleRoute = {
  path: '/loss-reasons',
  name: 'LossReasons',
  component: Output,
  meta: {
    allowAnonymous: false,
    ability: {
      subject: USER_SUBJECTS.COMPANY_SETTINGS,
      action: USER_ACTIONS.READ,
    },
  },
};

export default (router) => {
  router.addRoute(ROUTE_NAMES.BASE_LAYOUT, moduleRoute);
};

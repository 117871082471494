import { ROUTE_NAMES, USER_ACTIONS, USER_SUBJECTS } from '@/utils/const.js';
const Events = () => import('./pages');

const moduleRoute = {
  path: '/losses',
  name: 'EventsPage',
  component: Events,
  meta: {
    allowAnonymous: false,
    ability: {
      subject: USER_SUBJECTS.LOSSES,
      action: USER_ACTIONS.READ,
    },
  },
};

export default (router) => {
  router.addRoute(ROUTE_NAMES.BASE_LAYOUT, moduleRoute);
};

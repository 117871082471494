import { computed, ref } from 'vue';
import { USER_ACTIONS, USER_SUBJECTS } from '@/utils/const';
import { useRoute } from 'vue-router/composables';
import { watchEffect } from 'vue';
const sidebarTypes = {
  MAIN: 'MAIN',
  PROFILE: 'PROFILE',
  COMPANY: 'COMPANY',
};

const companySidebarItems = [
  {
    $ID: 'General',
    Action: {
      $Type: 'OpenPage',
      Page: 'GeneralSettingsCompany',
    },
    Icon: 'settings',
    Caption: 'sidebar.titles.general',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.COMPANY_SETTINGS,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'Users',
    Action: {
      $Type: 'OpenPage',
      Page: 'Users',
    },
    Icon: 'user-sidebar',
    ViewBox: '0 0 20 20',
    Caption: 'sidebar.titles.users',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.COMPANY_USERS,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'BeedigitApi',
    Action: {
      $Type: 'OpenPage',
      Page: 'BeedigitApi',
    },
    Icon: 'user-sidebar',
    ViewBox: '0 0 20 20',
    Caption: 'sidebar.titles.beedigitApi',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.API_KEY,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'LossReasons',
    Action: {
      $Type: 'OpenPage',
      Page: 'LossReasons',
    },
    Icon: 'grid',
    ViewBox: '0 0 20 20',
    Caption: 'sidebar.titles.lossReasons',
    group: 'loss',
    ability: {
      subject: USER_SUBJECTS.COMPANY_SETTINGS,
      action: USER_ACTIONS.READ,
    },
  },
];

const profileSidebarItems = [
  {
    $ID: 'Profile',
    Action: {
      $Type: 'OpenPage',
      Page: 'SettingsProfile',
    },
    Icon: 'settings',
    Caption: 'sidebar.titles.profile',
    group: 'main',
  },
  {
    $ID: 'Password',
    Action: {
      $Type: 'OpenPage',
      Page: 'Password',
    },
    Icon: 'lock',
    Caption: 'sidebar.titles.password',
    group: 'main',
  },
];

const mainSidebarItems = [
  // {
  //   $ID: 'Overview',
  //   Action: {
  //     $Type: 'OpenPage',
  //     Page: 'Overview',
  //   },
  //   Icon: 'overview',
  //   IconClass: 'fill-icon',
  //   Caption: 'sidebar.titles.overview',
  //   group: 'main',
  // },
  {
    $ID: 'Boxes',
    Action: {
      $Type: 'OpenPage',
      Page: 'Boxes',
    },
    Icon: 'work-center',
    Caption: 'sidebar.titles.boxes',
    IconClass: 'fill-icon',
    group: 'main',
  },
  {
    $ID: 'EffieciencyLoss',
    Action: {
      $Type: 'OpenPage',
      Page: 'EventsPage',
    },
    Icon: 'clipboard',
    Caption: 'sidebar.titles.losses',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.LOSSES,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'JobOrders',
    Action: {
      $Type: 'OpenPage',
      Page: 'JobOrders',
    },
    Icon: 'job-orders',
    IconClass: 'fill-icon',
    ViewBox: '0 -960 960 960',
    Caption: 'sidebar.titles.jobOrders',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.JOB_ORDERS,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'Output',
    Action: {
      $Type: 'OpenPage',
      Page: 'Output',
    },
    Icon: 'output',
    IconClass: 'fill-icon',
    ViewBox: '0 -960 960 960',
    Caption: 'sidebar.titles.output',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.OUTPUTS,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'Shifts',
    Action: {
      $Type: 'OpenPage',
      Page: 'Shifts',
    },
    Icon: 'shifts',
    IconClass: 'fill-icon',
    ViewBox: '0 -960 960 960',
    Caption: 'sidebar.titles.shifts',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.SHIFTS,
      action: USER_ACTIONS.READ,
    },
  },
  {
    $ID: 'Analytics',
    Action: {
      $Type: 'OpenPage',
      Page: 'Analytics',
    },
    Icon: 'analytics',
    // IconClass: 'fill-icon',
    ViewBox: '0 0 20 20',
    Caption: 'sidebar.titles.analytics',
    group: 'main',
    ability: {
      subject: USER_SUBJECTS.ANALYTICS,
      action: USER_ACTIONS.READ,
    },
  },
  // {
  //   $ID: 'BotLinks',
  //   Caption: 'sidebar.titles.bot',
  //   Icon: 'chat-bot',
  //   Action: {
  //     $Type: 'OpenPage',
  //     Page: 'BotLinks',
  //   },
  //   group: 'support',
  // },
  // {
  //   $ID: 'ApiSettings',
  //   Caption: 'sidebar.titles.apiSettings',
  //   Icon: 'link',
  //   Action: {
  //     $Type: 'OpenPage',
  //     Page: 'ApiSettings',
  //   },
  //   group: 'api',
  // },
  {
    $ID: 'SettingsAddBox',
    Caption: 'sidebar.titles.addWorkCenter',
    Icon: 'plus-circle',
    Action: {
      $Type: 'OpenPage',
      Page: 'SettingsAddBox',
    },
    group: 'addBox',
    ability: {
      subject: USER_SUBJECTS.NEW_WORK_CENTER,
      action: USER_ACTIONS.READ,
    },
  },
  // {
  //   $ID: 'Faq',
  //   Caption: 'sidebar.titles.faq',
  //   Icon: 'help-circle',
  //   Action: {
  //     $Type: 'OpenPage',
  //     Page: 'Faq',
  //   },
  //   group: 'support',
  // },
];
const sidebarType = ref(sidebarTypes.MAIN);
export default function useSidebar() {
  const route = useRoute();

  const sidebarItems = ref([]);
  const isSidebarOpen = ref(false);

  let _onClose = null;

  const isCompanySidebar = computed(() => {
    return sidebarType.value === sidebarTypes.COMPANY;
  });

  const isProfileSidebar = computed(() => {
    return sidebarType.value === sidebarTypes.PROFILE;
  });

  const initSidebar = (routeName = null) => {
    routeName = routeName ?? route.name;

    if (companySidebarItems.find((item) => item.Action.Page === routeName)) {
      sidebarType.value = sidebarTypes.COMPANY;
      return;
    }

    if (profileSidebarItems.find((item) => item.Action.Page === routeName)) {
      sidebarType.value = sidebarTypes.PROFILE;
      return;
    }
  };

  const openSidebar = () => {
    isSidebarOpen.value = true;
  };

  const closeSidebar = () => {
    isSidebarOpen.value = false;
    if (_onClose) {
      _onClose();
    }
  };

  const onCloseSidebar = (onClose) => {
    _onClose = onClose;
  };

  const setSidebarType = (type) => {
    sidebarType.value = type;
  };

  watchEffect(() => {
    initSidebar(route.name);
  });

  watchEffect(() => {
    if (sidebarType.value == sidebarTypes.COMPANY) {
      sidebarItems.value = companySidebarItems;
    } else if (sidebarType.value == sidebarTypes.PROFILE) {
      sidebarItems.value = profileSidebarItems;
    } else {
      sidebarItems.value = mainSidebarItems;
    }
  });

  return {
    sidebarTypes: sidebarTypes,
    sidebarType,
    sidebarItems,
    initSidebar,
    openSidebar,
    closeSidebar,
    onCloseSidebar,
    isSidebarOpen,
    isCompanySidebar,
    isProfileSidebar,
    setSidebarType,
  };
}
